import React, { useEffect, useLayoutEffect, useState, Suspense, lazy } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.scss';
import { updateViewPortMeta, isMobileOS, getOperatingSystem } from './Helper/utilities';
import ZenKakuGothicAntique from "../Assets/Fonts/ZenKakuGothicAntique-Regular.ttf";
import { MsalProvider } from "@azure/msal-react";

// Lazy load components
const Main = lazy(() => import('./Layouts/Main'));
const Store = lazy(() => import('./Store'));
const BlockchainsProvider = lazy(() => import('./BlockchainsProvider'));

function App({ instance }) {
  useLayoutEffect(() => {
    updateViewPortMeta();
  }, []);

  const [fontLoaded, setFontLoaded] = useState(false);
  useEffect(() => {
    // Function to check if font is loaded
    const font = new FontFace('ZenKakuGothicAntique-Regular', `url(${ZenKakuGothicAntique})`);
    font.load()
      .then(() => {
        document.fonts.add(font);
        setTimeout(() => {
          setFontLoaded(true);
        }, 500);
      })
      .catch((error) => {
        console.error('Font failed to load:', error);
      });
      const { pathname, search } = window.location;
      const isAnywall = process.env.REACT_APP_COMPANY === 'anywall';
      const isDev = process.env.REACT_APP_ENV === 'development';
      const params = new URLSearchParams(search);
      const source = params?.get('source');
      if (pathname?.startsWith('/gallery') || isAnywall || source === 'appv2' || isDev) {
        const scriptElement = document.getElementById('vanessa-cvfk3j');
        if (scriptElement) {
          scriptElement.remove();
        }
      }
    // Create header links dependent upon company env variable
    const linkIcon = document.createElement("link");
    linkIcon.rel = "icon";
    linkIcon.href = isAnywall ? "/anywall-favicon.png" : "/favicon.ico.png";
    document.head.appendChild(linkIcon);

    const link = document.createElement("link");
    link.rel = "apple-touch-icon";
    link.href = isAnywall ? "/anywall-favicon.png" : "/favicon.ico.png";
    document.head.appendChild(link);

    const linkMainFest = document.createElement("link");
    linkMainFest.rel = "manifest";
    linkMainFest.href = isAnywall ? "/anywall-manifest.json" : "/manifest.json";
    document.head.appendChild(linkMainFest);

    const title = document.createElement("title");
    title.textContent = `${isAnywall ? 'Anywall' : 'Cur8'}: Where digital ownership lives. Welcome home.`;
    document.head.appendChild(title);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!fontLoaded) return null; // Or return a loading component

  return (
    <div className={`App ${!isMobileOS() ? "h-full" : "is-mobile"} ${getOperatingSystem() === 'iOS' ? 'is-ios' : ''}`}>
        <MsalProvider instance={instance}>
          <Suspense fallback={''}>
            <Store>              
              <BlockchainsProvider>
                  <Main />
              </BlockchainsProvider>
            </Store>
          </Suspense>
        </MsalProvider>
      </div>
  );
}

export default App;
