import { getShortDate } from "./getDate";
import nftFormat from "./nftFormat";
import image from "Assets/Icons/image.svg";
import audio from "Assets/Icons/music.svg";
import video from "Assets/Icons/video.svg";
import model from "Assets/Icons/model.svg";
import pdf from "Assets/Icons/pdf.svg";
import html from "Assets/Icons/html.svg";
import epub from "Assets/Icons/read-book.svg";
import MetaDummy from "Assets/Images/meta.png";
import validationCheck from "./validationCheck";
import TicketIcon from "App/Components/IconsComponents/TicketsIcon";
import MembershipIcon from "App/Components/IconsComponents/MembershipIcon";
import MarketplaceIcon from "Assets/Icons/marketplace.png";
import GamingIcon from "App/Components/IconsComponents/GamingIcon";
import AssetBackedIcon from "App/Components/IconsComponents/AssetBackedIcon";
import openseaImg from "Assets/Images/Marketplace_Logos/Opensea.png";
import artBlockImg from "Assets/Images/Marketplace_Logos/Art Blocks.png";
import asyncArtImg from "Assets/Images/Marketplace_Logos/Async Art.png";
import atomicHubImg from "Assets/Images/Marketplace_Logos/AtomicHub.png";
import axieInfinityImg from "Assets/Images/Marketplace_Logos/Axie Infinity.png";
import blurImg from "Assets/Images/Marketplace_Logos/BLUR.png";
import elementImg from "Assets/Images/Marketplace_Logos/Element NFT Marketplace.png";
import enjinImg from "Assets/Images/Marketplace_Logos/Enjin.png";
import exchangeArtImg from "Assets/Images/Marketplace_Logos/ExchangeArt.png";
import folorProtocolImg from "Assets/Images/Marketplace_Logos/Floor Protocol.png";
import hyperspaceImg from "Assets/Images/Marketplace_Logos/Hyperspace.png";
import jpgStoreImg from "Assets/Images/Marketplace_Logos/JPG.Store.png";
import knownOriginImg from "Assets/Images/Marketplace_Logos/KnownOrigin.io.png";
import lfjImg from "Assets/Images/Marketplace_Logos/LFJ.png";
import looksRareImg from "Assets/Images/Marketplace_Logos/LooksRare.png";
import magicEdenImg from "Assets/Images/Marketplace_Logos/MagicEden.png";
import makersPlaceImg from "Assets/Images/Marketplace_Logos/MakersPlace.png";
import niftyGatewayImg from "Assets/Images/Marketplace_Logos/Nifty Gateway Studio.png";
import mintableImg from "Assets/Images/Marketplace_Logos/Mintable.png";
import okxImg from "Assets/Images/Marketplace_Logos/OKX.png";
import objktImg from "Assets/Images/Marketplace_Logos/Objkt.png";
import raribleImg from "Assets/Images/Marketplace_Logos/Rarible.png";
import solanartImg from "Assets/Images/Marketplace_Logos/Solanart.png";
import suprerRareImg from "Assets/Images/Marketplace_Logos/Super Rare.png";
import tensorImg from "Assets/Images/Marketplace_Logos/Tensor.png";
import troveImg from "Assets/Images/Marketplace_Logos/Trove.png";
import x2y2Img from "Assets/Images/Marketplace_Logos/X2Y2.png";
import zoraImg from "Assets/Images/Marketplace_Logos/Zora.png";
import foundationImg from "Assets/Images/Marketplace_Logos/foundation.png";
import infinity8Img from "Assets/Images/Marketplace_Logos/infinity 8.png";
import kreateImg from "Assets/Images/Marketplace_Logos/Kreate.png";
import dripImg from "Assets/Images/Marketplace_Logos/drip.haus.png";
import dropspotImg from "Assets/Images/Marketplace_Logos/dropspot.png";
import mallowImg from "Assets/Images/Marketplace_Logos/mallow.png";
import manifoldImg from "Assets/Images/Marketplace_Logos/manifold.xyz.png";
import remxImg from "Assets/Images/Marketplace_Logos/remx.png";
import traderImg from "Assets/Images/Marketplace_Logos/trader.xyz.png";
import { WORDS } from "App/constants";
import { ROUTES_WORDS } from "App/constants/routes";

export const getSplice = (array, targetCount) => {
    const totalCount = Math.ceil(array.length / targetCount);
    const temp = [];
    for (let k = 0; k < totalCount; k++) {
        const spl = k * targetCount;
        const list = array.slice(spl, spl + targetCount);
        temp.push(list);
    }
    return temp;
}
export const capitalizeFirstLetter = (str) => {
    return str? str?.charAt(0)?.toUpperCase() + str?.slice(1):"";

}
export const thousandSeparator = (number) => {
    return Number(number)?.toLocaleString('en-US')
}
export const isDataURL = (url) => {
    url.includes('data:')
}
export const getBlobFromUrl = (url, callBack) => {
    try {
        const myRequest = new Request(url);
        if (isDataURL(url)) {
            callBack(url)
        } else {
            fetch(myRequest, { headers: { "Cache-Control": 'no-cache' } })
                .then(response => response.blob())
                .then(blob => {

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        callBack(base64data)
                    }
                });
        }
    } catch (error) {
        console.error('Error getBlobFromUrl', error);
    }
}
export const geReWriteURL = (url) => {
    return new Promise((resolve) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const r = URL.createObjectURL(blob)
                resolve(r)
            })
            .catch(e => {
                resolve('')
            })
    })
}
export const sortObject = (arr, sortVal, originalArr = [], allCollectionData = []) => {
    switch (sortVal) {
        case ("Name A-Z"):
            return arr?.sort((a, b) => {
                const nameA = a["name"]?.trim().toLowerCase();
                const nameB = b["name"]?.trim().toLowerCase();
                return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
            });

        case ("Name Z-A"):
            return arr?.sort((a, b) => {
                const nameA = a["name"]?.trim().toLowerCase();
                const nameB = b["name"]?.trim().toLowerCase();

                return nameA < nameB ? 1 : nameA > nameB ? -1 : 0;
            });
        case ("Size L-H"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) > Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case ("Size H-L"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) < Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case "Collection A-Z":
            return arr.sort((a, b) => {
                const nameA = (a?.collectionName ?? a?.name)?.trim().toLowerCase();
                const nameB = (b?.collectionName ?? b?.name)?.trim().toLowerCase();

                return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
            });
        case "Collection Z-A":
            return arr.sort((a, b) => {
                const nameA = (a?.collectionName ?? a?.name)?.trim().toLowerCase();
                const nameB = (b?.collectionName ?? b?.name)?.trim().toLowerCase();

                return nameA < nameB ? 1 : nameA > nameB ? -1 : 0;
            });
        case "Count H-L":
            return arr?.sort((a, b) =>
                Number(a["quantity"])
                    ? Number(b["quantity"])
                        ? Number(a["quantity"]) < Number(b["quantity"])
                            ? 1
                            : -1
                        : -1
                    : 1
            );
        case "Count L-H":
            return arr.sort((a, b) =>
                Number(a["quantity"])
                    ? Number(b["quantity"])
                        ? Number(a["quantity"]) > Number(b["quantity"])
                            ? 1
                            : -1
                        : -1
                    : 1
            );
        case "Floor Price H-L":
            arr = arr.map((pieces) => ({ ...pieces, floorPrice: pieces?.metrics?.length ? pieces?.metrics[0]?.floorPrice ?? 0 : 0 }))
            return arr.sort((a, b) => Number(a["floorPrice"]) ? Number(b["floorPrice"]) ? Number(a["floorPrice"]) < Number(b["floorPrice"]) ? 1 : -1 : -1 : 1);
        case "Release Date L-F":
            return arr.sort((a, b) => a["mintDateFull"] ? b["mintDateFull"] ? a["mintDateFull"] < b["mintDateFull"] ? 1 : -1 : -1 : 1);
        case "Verified L-F":
            return arr.sort((a, b) => a["verifiedDate"] ? b["verifiedDate"] ? a["verifiedDate"] < b["verifiedDate"] ? 1 : -1 : -1 : 1);
        case "Release Date F-L":
            return arr.sort((a, b) =>
                a["mintDateFull"]
                    ? b["mintDateFull"]
                        ? a["mintDateFull"] > b["mintDateFull"]
                            ? 1
                            : -1
                        : 1
                    : -1
            );
        case "Recent Additions":
            let oldest = allCollectionData?.sort((x, y) => new Date(x?.userWalletAssetLastAcquiredDate) > new Date(y?.userWalletAssetLastAcquiredDate) ? 1 : -1)[0]?.userWalletAssetLastAcquiredDate ?? 0;
            let sorted = arr.sort((a, b) => {
                if ((new Date(a?.userWalletAssetLastAcquiredDate) - new Date(oldest)) < 60000 && (new Date(b?.userWalletAssetLastAcquiredDate) - new Date(oldest)) < 60000) {
                    return a["mintDateFull"]
                        ? b["mintDateFull"]
                            ? new Date(a["mintDateFull"]) < new Date(b["mintDateFull"])
                                ? 1
                                : -1
                            : -1
                        : 1
                }
                else {
                    return new Date(b?.userWalletAssetLastAcquiredDate) - new Date(a?.userWalletAssetLastAcquiredDate)
                }
            });
            return sorted;
        default:
            if (originalArr.length) {
                return originalArr;
            } else {
                sortVal = sortVal?.toLowerCase()
                return arr.sort((a, b) => a[sortVal] ? b[sortVal] ? a[sortVal]?.toLowerCase() > b[sortVal]?.toLowerCase() ? 1 : -1 : -1 : 1);
            }
    }

}

//Nft collection assetId list Api

export const getMetadata = (data, favKey) => {
    let image = '';
    if (data.onchain_metadata?.asset?.ipfs) {
        image = `${data.onchain_metadata?.asset?.url}${data.onchain_metadata?.asset?.ipfs}`
    } else if (data.metadata?.logo) {
        image = `data:image/png;base64,${data.metadata?.logo}`;
    }
    let files = [];
    if (data?.onchain_metadata?.files?.length) {
        let thumbimage = nftFormat(data.onchain_metadata?.image || image)
        files = data?.onchain_metadata?.files?.map((item => {
            let src = JSON.parse(JSON.stringify(item?.src ?? item?.fileFromsUrl ?? ""))
            let mediaType = JSON.parse(JSON.stringify(item?.mediatype ?? item?.mediaType ?? item?.mimeType ?? ""))
            if (Array.isArray(src)) {
                src = src.join('');
            }
            if (src?.includes('html')) {
                item.sType = 'html';
            } else if (src?.includes('ipfs')) {
                src = nftFormat(src, '?func=proxy');
                item.sType = 'ipfs';
            } else if (src?.includes('base64')) {
                item.sType = 'base64';
            }

            if (mediaType?.includes('html')) {
                item.mType = 'html';
            } else if (mediaType?.includes('image')) {
                item.mType = 'image';
            } else if (mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if (mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if (mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if (mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            item.newsrc = src;
            item.thumbimage = thumbimage;
            return item
        }))
    } else if (data?.onchain_metadata?.chain) {
        let f
        if (data?.onchain_metadata?.extra_metadata?.artifactUri && data?.onchain_metadata?.extra_metadata?.formats?.length) {
            f = data?.onchain_metadata?.extra_metadata?.formats.filter(f => f.uri === data?.onchain_metadata?.extra_metadata?.artifactUri)?.[0];
        }
        else if (data?.onchain_metadata?.extra_metadata?.generatorUri) {
            f = {
                uri: data?.onchain_metadata?.extra_metadata?.generatorUri,
                mimeType: "html/text"
            }

            if (f.uri.startsWith("ipfs://")) {
                f.uri = f.uri.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/");
            }
            else if (f.uri.startsWith("onchfs://")) {
                f.uri = f.uri.replace("onchfs://", "https://onchfs.fxhash2.xyz/");
            }

            if (data?.onchain_metadata?.extra_metadata?.iterationHash) {
                f.uri += `/?fxhash=${data?.onchain_metadata?.extra_metadata?.iterationHash}`;
            }
        }
        if (f) {
            let item = {
                "src": f.uri,
                "mediaType": f.mimeType,
                "nftcdnfile": f.uri
            }
            let src = f.uri;
            if (src?.includes('html')) {
                item.sType = 'html';
            } else if (src?.includes('ipfs')) {
                item.sType = 'ipfs';
            } else if (src?.includes('base64')) {
                item.sType = 'base64';
            }

            let mediaType = f.mimeType;
            if (mediaType?.includes('html') || mediaType === 'application/x-directory') {
                item.mType = 'html';
            } else if (mediaType?.includes('image')) {
                item.mType = 'image';
            } else if (mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if (mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if (mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if (mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            files.push(item);
        }
        if (data?.onchain_metadata?.video_url) {
            files.push({
                "src": data?.onchain_metadata?.video_url,
                "nftcdnfile": data?.onchain_metadata?.video_url.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/"),
                "mediaType": "video",
                "mType": "mp4"
            });
        }
        if (data?.onchain_metadata?.extra_metadata?.animation_original_url) {
            files.push({
                "src": data?.onchain_metadata?.extra_metadata?.animation_original_url,
                "nftcdnfile": data?.onchain_metadata?.extra_metadata?.animation_original_url.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/"),
                "mediaType": "html",
                "mType": "html"
            });
        }
    } else {
        let src = nftFormat(data.onchain_metadata?.image || image);
        files = [{
            "src": src,
            "name": data.onchain_metadata?.name || data.metadata?.name || data.name,
            "mediaType": "image/png",
            "mType": "image",
            "sType": "ipfs",
            "newsrc": src,
            nftcdnfile: data?.nftcdnimage
        }]
    }
    return {
        collectionName: data.collectionName ?? data.onchain_metadata?.collectionName,
        collectionImage: data.collectionImage,
        collectionImage256: data.collectionImage256,
        collectionImage512: data.collectionImage512,
        collectionImage1024: data.collectionImage1024,
        collectionId: data.collectionId,
        assetCount: data.assetCount,
        ownerCount: data.ownerCount,
        asset: data.asset,
        policy: data.policy_id,
        asset_name: data.asset_name,
        name: data?.onchain_metadata?.name || data?.onchain_metadata?.Name || data?.metadata?.name || data?.onchain_metadata?.title || data?.metadata?.title || data?.onchain_metadata?.["Asset Title"] || data?.metadata?.["Asset Title"] || data?.name,
        image: nftFormat(data.onchain_metadata?.image || image),
        description: data.onchain_metadata?.description || data.metadata?.description,
        type: data.onchain_metadata?.type ?? data.onchain_metadata?.Type,
        files: files,
        quantity: 1,
        isFavoriteCreatorAsset:data?.isFavoriteCreatorAsset,
        isCollectionHided: data?.isCollectionHided,
        isCollectionFavourite: data[favKey],
        isHidedCollectionAsset: data?.isHidedCollectionAsset,
        isFavoriteCollectionAsset: data?.isFavoriteCollectionAsset,
        isFavourite: data.isFavouriteNFT,
        networkType: data.networkType,
        value: data.onchain_metadata?.value,
        priceSource: data.onchain_metadata?.priceSource,
        mintDate: getShortDate(data.mintDate),
        lockDate: getShortDate(data.lockDate),
        mintDateFull: data.mintDate,
        lockDateFull: data.lockDate,
        walletId: data?.walletId,
        ownersCount: data?.ownersCount,
        items: data?.items,
        mint_or_burn_count: data?.mint_or_burn_count,
        category: data?.category ?? { name: data.categoryName },
        categoryId: data?.categoryId,
        firstSubcategoryId: data?.firstSubcategoryId,
        secondSubcategoryId: data?.secondSubcategoryId,
        onchain_metadata: data.onchain_metadata,
        community: { id: data.communityId, name: data.communityName, displayPicture256: data.communityDisplayPicture256, communitySlug: data.communitySlug },
        aspectRatios: data.aspectRatios,
        collectionEditionsId: data.collectionEditionsId,
        collectionStorageId: data.collectionStorageId,
        tags: data.tags,
        types: data.types,
        utility: data?.utility,
        id: data?.id,
        nftcdnimage: data.nftcdnimage,
        nftcdnimage256: data.nftcdnimage256,
        nftcdnimage512: data.nftcdnimage512,
        nftcdnimage1024: data.nftcdnimage1024,
        termsId: data.termsId,
        subCategoryName: data?.subCategoryName,
        showcase: data.showcase,
        collage: data.collage,
        metrics: data?.metrics,
        marketplace: data?.marketplace,
        minted: data?.minted,
        nfttype: data?.nfttype,
        collectionCollectionId: data?.collectionCollectionId,
        blockchainType: data?.blockchainType,
        contractAddress: (!["solana", "bitcoin"]?.includes(data?.blockchainType?.code)) ? data?.contractAddress : (data?.onchain_metadata?.contract_address ?? data?.contractAddress),
        createdAt: data?.createdAt,
        userWalletAssetCreatedAt: data?.userWalletAssetCreatedAt,
        assetDetailsUpdatedAt: data?.assetDetailsUpdatedAt,
        collectionUpdatedAt: data?.collectionUpdatedAt,
        communityUpdatedAt: data?.communityUpdatedAt,
        proofOfCraft: data?.proofOfCraft,
        curatorProfile: data?.curatorProfile,
        userWalletAssetLastAcquiredDate: data?.blockchainType?.code === 'cardano' ? data?.userWalletAssetCreatedAt : data?.userWalletAssetLastAcquiredDate,
    }
}

export const getURL = (url = '', size = 256) => {
    return url;
}
export const getFrameURL = (url, size) => {
    return url;
}
export const getFrameURLFromCDN = (url) => {
    return url;
}
export const convertPriceToAda = (priceValue) => {
    const price = (priceValue / 1000000);
    return price ? thousandCommaSeparator(price?.toFixed(2)) : price;
}

export const thousandCommaSeparator = (amount) => {
    return amount ? amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}
export const formatNumberWithThousand = (numberValue = 0, maxDecimalLength = 6) => {
    let num = Number(numberValue);

    
    if (num >= 1) {
        num = parseFloat(num.toFixed(1)); // For numbers >= 1, round to 1 decimal place
    } else {
        // For numbers < 1, preserve up to maxDecimalLength decimal places, restricting beyond maxDecimalLength
        const decimalPart = num?.toString()?.split('.')?.[1];
        num = decimalPart && decimalPart.length > maxDecimalLength ? parseFloat(num.toFixed(maxDecimalLength)) : num;
    }

    if (num >= 1000 && num < 1000000) {
        return thousandCommaSeparator((num / 1000)?.toFixed(1)?.replace(/\.0$/, '')) + 'K';
    } if (num >= 1000000 && num < 1000000000) {
        return thousandCommaSeparator((num / 1000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'M';
    } if (num >= 1000000000 && num < 1000000000000) {
        return thousandCommaSeparator((num / 1000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'B';
    } if (num >= 1000000000000 && num < 1000000000000000) {
        return thousandCommaSeparator((num / 1000000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'T';
    } if (num >= 1000000000000000) {
        return thousandCommaSeparator((num / 1000000000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'Q';
    }

    return num;
}
export const addFractionDigits = (amount, digit) => {
    return amount ? amount.toLocaleString("en", { minimumFractionDigits: digit || 2 }) : 0;
}
export const convertJsonToArray = (data) => {
    try {
        const convertData = JSON.parse(data);
        return typeof convertData === 'object' ? convertData : [];
    } catch (error) {
        return [];
    }
}
export const getOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return "Safari";
    }
    return "unknown";
}
export const isMobileOS = () => {
    let os = getOperatingSystem();
    return os === "Windows Phone" || os === "Android" || os === "iOS";
}
export const updateViewPortMeta = () => {
    const v = getOperatingSystem();
    const el = document.querySelector('meta[name=viewport]');
    if (v === 'Android') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1')
        }
    }
    if (v === 'iOS') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
        }
    }
}
export const dataURLtoBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};
export const getBLOBUrl = (blob) => {
    try {
        return URL.createObjectURL(blob)
    } catch (error) {
        return blob
    }
}
export const scrollToTop = (x = 0, y = 0, element = window, disableSmooth = false) => {
    element?.scrollTo({
        top: y,
        left: x,
        behavior: disableSmooth ? 'auto' : "smooth"
    });
}
export const togglePaddingMain = (mode) => {
    // const os = getOperatingSystem();
    // const isMobile = window.innerWidth <= 768
    // const main = document.getElementById('mainContent');
    // if (main && isMobile && os === 'iOS') {
    //     if (mode) {
    //         main.style.paddingTop = 0;
    //     } else {
    //         main.style.paddingTop = '4.9375rem';
    //     }
    // }
}
export const toggleOverflow = (mode) => {
    const isMobile = window.innerWidth <= 768
    const main = document.body
    if (main && isMobile) {
        if (mode) {
            main.style.overflow = 'hidden';
        } else {
            main.style.overflow = 'auto';
        }
    }
}
export const popupMobileScrollAdj = (mode) => {
    togglePaddingMain(mode);
    toggleOverflow(mode)
}


export const removeDuplicateByElement = (array, key) => {
    const uniqueIds = [];
    // eslint-disable-next-line array-callback-return
    array.filter(ele => {
        const includeArr = uniqueIds.some(x => x[key] === ele[key]);
        if (!includeArr)
            uniqueIds.push(ele);
    })
    return uniqueIds;
}

export const objectToFormdata = (payload = {}, overrideKey) => {
    const formData = new FormData();
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            if (element && typeof element === 'object' && !(element instanceof Blob)) {
                if (Array.isArray(element)) {
                    if (!element?.length) {
                        formData.append(`${key}`, JSON.stringify(element))
                    } else {
                        for (let i = 0; i < element.length; i++) {
                            const elementItem = element[i];
                            if (elementItem instanceof Blob) {
                                formData.append(`${key}`, elementItem)
                            }
                            else {
                                formData.append(`${key}`, JSON.stringify(elementItem))
                            }
                        }
                    }
                }
                else {
                    formData.append(key, JSON.stringify(element))
                }
            }
            else {
                formData.append(key, element)
            }

        }
    }
    return formData
}

export const objectToNull = (payload = {}) => {
    let obj = {}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            obj[key] = element ?? null

        }
    }
    return obj
}

export const arrangeAssetData = (data) => {
    const result = data.reduce(function (r, a) {
        r[a.policy_id] = r[a.policy_id] || [];
        r[a.policy_id].push(a);
        return r;
    }, {});
    var groups = Object.keys(result).map(function (key) {
        return { policy_id: key, data: result[key], assetCount: result[key].length };
    });
    let firstAssetList = [];
    for (const iterator in result) {
        if (result[iterator].length) {
            firstAssetList.push(result[iterator][0]);
        }
    }
    let assetList = [];
    assetList = [...firstAssetList, ...assetList]
    const fetchAssets = [];
    assetList.forEach((item) => {
        const include = fetchAssets.some(x => x.asset === item.asset)
        if (!include) {
            if (!item?.asset) {
                item.asset = item?.asset_name;
            }
            fetchAssets.push(item)
        }
    })
    const assetCount = groups && groups.reduce((accumulator, object) => {
        return accumulator + object.assetCount;
    }, 0);
    return { groups, fetchAssets, assetCount }
}

export const filterCollections = (filterArray = [], filter, limit) => {
    let tempArray = [...filterArray];
    if (filter?.categoryIds && filter?.categoryIds?.length) {
        tempArray = tempArray?.filter((val) => (filter?.categoryIds?.includes(val?.categoryId) || filter?.categoryIds?.includes(val?.firstSubcategoryId) || filter?.categoryIds?.includes(val?.secondSubcategoryId)))
    }
    if (filter?.blockchains && filter?.blockchains?.length) {
        tempArray = tempArray?.filter((val) => {
            return filter?.blockchains?.includes(val?.blockchainType?.code);
        });
    }
    if (filter?.shapes && filter?.shapes?.length) {
        tempArray = tempArray?.filter((val) => {
            let aspectRatiosArray = val?.aspectRatios?.split(",") ?? [];
            if (aspectRatiosArray.length === 0) return false;
            const hasLandscape = aspectRatiosArray.filter(ar => parseFloat(ar) > 1).length > 0;
            const hasPortrait = aspectRatiosArray.filter(ar => parseFloat(ar) < 1).length > 0;
            const hasSquare = aspectRatiosArray.filter(ar => parseFloat(ar) === 1).length > 0;
            return (hasLandscape && filter.shapes.includes('landscape')) ||
                (hasPortrait && filter.shapes.includes('portrait')) ||
                (hasSquare && filter.shapes.includes('square'));
        }
        );
    }
    if (filter?.formatIds && filter?.formatIds?.length) {
        tempArray = tempArray?.filter((val) =>
            val.formats?.split(",").filter(v =>
                filter?.formatIds.filter(f =>
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if (filter?.storageIds && filter?.storageIds?.length) {
        tempArray = tempArray?.filter((val) =>
            filter?.storageIds.filter(f =>
                f === val.collectionStorageId
            ).length > 0
        );
    }

    if (filter?.utilitiesIds && filter?.utilitiesIds?.length) {

        tempArray = tempArray?.filter((val) =>
            val?.utility?.split(",").filter(v =>
                filter?.utilitiesIds.filter(f =>
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if (filter?.editionIds && filter?.editionIds?.length) {
        tempArray = tempArray?.filter((val) =>
            filter?.editionIds.filter(f =>
                f === val.collectionEditionsId
            ).length > 0
        );
    }
    if (filter?.typeIds && filter?.typeIds?.length) {
        tempArray = tempArray?.filter((val) =>
            val.types?.split(",").filter(v =>
                filter?.typeIds.filter(f =>
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if (filter?.tagIds && filter?.tagIds?.length) {
        tempArray = tempArray?.filter((val) =>
            val.tags?.split(",").filter(v =>
                filter?.tagIds.filter(f =>
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if (filter?.termsIds && filter?.termsIds?.length) {
        tempArray = tempArray?.filter((val) =>
            filter?.termsIds.filter(f =>
                f === val.termsId
            ).length > 0
        );
    }
    return limit ? tempArray?.slice(0, limit) : tempArray;
}

export const categorySort = (data) => {
    let updatedList = data
        ?.sort((fs, sec) => fs.name.localeCompare(sec.name))
        ?.map((el) => {
            return {
                ...el,
                subcategories: el?.subcategories?.length
                    ? el?.subcategories?.sort((fs, sec) =>
                        fs.name.localeCompare(sec.name)
                    )
                    : el?.subcategories,
            };
        });

    return updatedList;
};

export const getPreviewGalleryLink = (id, previewType) => {
    const pageMap = {
        '3d': 'meta',
        '2d': 'mono',
        '2ds': 'multi',
    };
    const page = pageMap[previewType];
    if (page) {
        window.open(`/gallery/${id}/${page}`, '_blank');
    }
}
export const deepCopy = (arr = []) => {
    return JSON?.parse(JSON.stringify(arr));
}

export const copyClipHandler = (value) => {
    var textArea = document.createElement("textarea");
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
};

export const splitWord = (value, num) => {
    return value?.length < num
        ? value
        : value?.slice(0, num / 2) +
        "..." +
        value?.slice(-(num / 2))
}

export const queryGenerator = (data) => {
    let queryLink = "";
    const queryData = Object.entries(data).filter(([key, val]) => (val && val?.length) || (val && typeof val === "number") || typeof val === "boolean");
    queryData.forEach(([key, val], i) => {
        queryLink = `${queryLink}${key}=${typeof val === "object" ? `[${val}]` : val}${(i + 1 !== queryData?.length) ? "&" : ""}`
    })
    return queryLink
}
export const getAvailableGallery = (collections, assets) => {
    return assets?.filter(x => collections?.some(y => y.asset === x?.asset));
}
export const findNumberFromString = (str) => {
    let matches = str.match(/\d+/g);
    if (matches)
        matches = parseInt(matches[0])
    return matches;
}

export const sequenceUpdate = (list, from, to) => {
    const listClone = [...list];
    if (from < to) {
        listClone.splice(to + 1, 0, listClone[from]);
        listClone.splice(from, 1);
    } else if (to < from) {
        listClone.splice(to, 0, listClone[from]);
        listClone.splice(from + 1, 1);
    }
    let listCloneUpdate = [...listClone]?.map((el, i) => {
        return { ...el, sequence: i + 1 }
    })
    return listCloneUpdate;
}

export const findSelectedSubcategory = (categoryArray) => {
    const selectedCategory = categoryArray?.find(item => item?.isSelected);

    if (selectedCategory) {
        const selectedSubcategory = selectedCategory?.subcategories?.find(sub => sub?.isSelected);
        return selectedSubcategory?.name || '';
    }

    return '';
};

export function appendUserName(value, social) {
    if (!value) return;
    const urls = {
        twitter: `https://twitter.com/`,
        youtube: `https://www.youtube.com/`,
        discord: `https://discord.gg/`,
        instagram: `https://www.instagram.com/`,
    }
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (!urlRegex.test(value)) {
        // If not a full URL, append the userName at the end
        const fullUrl = `${urls[social]}${value}`;
        return fullUrl;
    }
    // If it's already a full URL, return it as is
    return value;
}

export const MediaType = (media) => {
    if (media?.includes('html')) {
        return 'html';
    } else if (media?.includes('image')) {
        return 'image';
    } else if (media?.includes('video')) {
        return 'mp4';
    } else if (media?.includes('pdf')) {
        return 'pdf';
    } else if (media?.startsWith('model')) {
        return 'model';
    } else if (media?.includes('audio')) {
        return 'mp3';
    }
}

export const getMediaImage = (type) => {
    let mediaImg = ""
    switch (type) {
        case 'html':
            mediaImg = html;
            break;
        case 'image':
            mediaImg = image;
            break;
        case 'mp4':
        case 'video':
            mediaImg = video;
            break;
        case 'pdf':
            mediaImg = pdf;
            break;
        case 'model':
            mediaImg = model;
            break;
        case 'mp3':
        case 'audio':
            mediaImg = audio;
            break;
        case 'epub':
            mediaImg = epub;
            break;
        default:
            break;
    }
    return mediaImg
}

export const getCeilValue = (input) => {

    if (!input) return null;

    const number = parseFloat(input);
    if (!isNaN(number)) {
        return Math.ceil(number);
    } else {
        return null;
    }
}

export const adaToDollar = (ada) => {

    return ada * 0.93
}

export const getMetadataValue = (sources, keys) => {
    for (let j = 0; j < sources.length; j++) {
        const source = sources[j];
        if (source) {
            let props = Object.entries(source).map(([k, v]) => [k.toLowerCase(), v]);
            let value = props.filter(([k, v]) => keys.includes(k))?.[0]?.[1];
            if (!value || value === "") {
                value = props.filter(([k, v]) => {
                    let match = false;
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        if (k.indexOf(key) > -1) {
                            match = true;
                            break;
                        }
                    }
                    return match;
                })?.[0]?.[1];
            }
            if (value && value !== "") {
                return value;
            }
        }
    }
}

export const getDurationOfAudio = (duration) => {
    let formatDuration = duration?.replace("PT", "");
    let minsIndex = formatDuration?.indexOf("M");
    let secsIndex = formatDuration?.indexOf("S");
    let secs = 0;
    if (secsIndex > -1) {
        secs = formatDuration?.substring(minsIndex + 1, secsIndex - minsIndex + 1);
    }
    let mins = formatDuration?.substring(0, minsIndex);
    formatDuration = `${mins}:${(parseInt(secs) < 10 ? "0" : "") + parseInt(secs)}`;
    return formatDuration
}

export function formatLargeNumber(num) {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        // Define the threshold for each unit
        const thresholds = [
            { value: 1e12, symbol: 't' }, // trillion
            { value: 1e9, symbol: 'b' },  // billion
            { value: 1e6, symbol: 'm' },  // million
            { value: 1e3, symbol: 'k' }   // thousand
        ];

        // Loop through the thresholds to find the appropriate symbol
        for (let i = 0; i < thresholds.length; i++) {
            if (num >= thresholds[i].value) {
                // If the number is greater than or equal to the threshold, format it accordingly
                return (num / thresholds[i].value).toFixed(1) + thresholds[i].symbol;
            }
        }

        // If the number is smaller than the first threshold but larger than 100, then round to 0 dps
        if (number > 100) {
            return Math.round(number).toString();
        }
        // If the number is smaller than the first threshold, return it as is, rounded to 4 dps
        return (Math.round(number * 10000) / 10000).toString();
    } else {
        return null;
    }
}

export const numberWithCommas = (num) => {
    if (!num) return null;

    if (num === 0 || num === '0') return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        return number.toLocaleString();
    } else {
        return null;
    }
};

export const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

export const metaWallListData = () => {
    return [
        {
            id: 1,
            // imageUrl256: "/metagalleryassets/images/meta1256.jpg",
            imageUrl256: "/metagalleryassets/images/meta1.webp",
            isFavourite: false,
            name: "Small Gallery",
        }, {
            id: 2,
            // imageUrl256: "/metagalleryassets/images/meta2256.jpg",
            imageUrl256: "/metagalleryassets/images/meta2.webp",
            isFavourite: false,
            name: "Large Gallery",
        }, {
            id: 3,
            // imageUrl256: "/metagalleryassets/images/meta3256.jpg",
            imageUrl256: "/metagalleryassets/images/meta3.webp",
            isFavourite: false,
            name: "Cocktail Bar",
        }
    ]
}

export const getMetaThumbImageById = (id) => {
    let tempImage = MetaDummy, metaWallList = metaWallListData();
    if (id) {
        tempImage = metaWallList?.find(bg => Number(bg?.id) === Number(id))?.imageUrl256
    }
    return tempImage;
}

export const getBlockChainLable = ({ blockChainList = [], blockChainId = '', blockChainCode = '', marketplaceChains = [] }) => {
    const selectedBlockchain = blockChainList?.find((item) => (item?.id === blockChainId || item?.code === blockChainCode));
    if (marketplaceChains?.includes(selectedBlockchain?.code)) {
        return "Marketplace Collection URL";
    }
    return selectedBlockchain?.code === 'cardano' ? 'Policy ID' : (selectedBlockchain?.code === 'solana' ? 'Collection ID' : 'Smart Contract');
}
export const getMinWidthForDetailsTitle = ({ blockChainList = [], blockChainId = '', blockChainCode = '' }) => {
    const selectedBlockchain = blockChainList?.find((item) => (item?.id === blockChainId || item?.code === blockChainCode));
    return selectedBlockchain?.code === 'cardano' ? 'min-w-[3.8rem]' : (selectedBlockchain?.code === 'solana' ? 'min-w-[4.1rem]' : 'min-w-[5.971rem]');
}

export const ImageLoader = (image) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
    };
}

export const splitArrayEqually = (arr) => {
    // Find the middle index
    const middleIndex = Math.ceil(arr.length / 2);

    // Split the array into two parts
    const firstSet = arr.slice(0, middleIndex);
    const secondSet = arr.slice(middleIndex);

    return { firstSet, secondSet };
}

export const oddAndEvenSplitHandler = (data) => {
    let oddSet = data?.filter((el, i) => (i + 1) % 2 !== 0);
    let evenSet = data?.filter((el, i) => (i + 1) % 2 === 0);
    return { oddSet, evenSet }
}

export const FindOddOrEven = (num) => {
    let isOdd = num % 2 !== 0;
    let isEven = num % 2 === 0;
    return { isOdd, isEven }
}

export const interpolate = (color1, color2, percent) => {
    let r1;
    let g1, b1, r2, g2, b2;
    if (color1.startsWith("#")) {
        // Convert the hex colors to RGB values
        r1 = parseInt(color1.substring(1, 3), 16);
        g1 = parseInt(color1.substring(3, 5), 16);
        b1 = parseInt(color1.substring(5, 7), 16);
    } else if (color1.startsWith("rgb")) {
        let rgbVals = color1.replace(")", "").substring(color1.indexOf("(") + 1).split(",");
        r1 = parseInt(rgbVals[0]);
        g1 = parseInt(rgbVals[1]);
        b1 = parseInt(rgbVals[2]);
    }

    if (color2.startsWith("#")) {
        // Convert the hex colors to RGB values
        r2 = parseInt(color2.substring(1, 3), 16);
        g2 = parseInt(color2.substring(3, 5), 16);
        b2 = parseInt(color2.substring(5, 7), 16);
    } else if (color2.startsWith("rgb")) {
        let rgbVals = color2.replace(")", "").substring(color2.indexOf("(") + 1).split(",");
        r2 = parseInt(rgbVals[0]);
        g2 = parseInt(rgbVals[1]);
        b2 = parseInt(rgbVals[2]);
    }

    // Interpolate the RGB values
    const r = Math.round(r1 + (r2 - r1) * percent);
    const g = Math.round(g1 + (g2 - g1) * percent);
    const b = Math.round(b1 + (b2 - b1) * percent);

    // Convert the interpolated RGB values back to a hex color
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export const truncateText = (text, maxLetter) => {
    if (!text) return '';
    if (text?.length > maxLetter) {
        return text?.slice(0, maxLetter) + '... ';
    }
    return text;
};

export const getBgImageByWidth = (width, bgImageObj) => {
    let bgImage = '';
    if (width > 2048) {
        bgImage = bgImageObj?.bgImage ?? bgImageObj?.bgImage2048;
    } else if (width <= 2048 && width > 1024) {
        bgImage = bgImageObj?.bgImage2048;
    } else if (width <= 1024 && width > 512) {
        bgImage = bgImageObj?.bgImage1024;
    } else if (width <= 512 && width > 256) {
        bgImage = bgImageObj?.bgImage512;
    } else if (width <= 256) {
        bgImage = bgImageObj?.bgImage256;
    } else {
        bgImage = bgImageObj?.bgImage256;
    }
    return bgImage
}
export const isWithinLimit = (available, current) => {
    return available > current;
}
export const sortValueChangeHandlerForGroupByFilter = (sortType, isCreator = false) => {
    if (sortType === 'Recency') {
        return isCreator ? "Verified L-F" : "Recent Additions";
    }
    if (sortType === 'Name') {
        return "Collection A-Z";
    }
    if (sortType === 'Price') {
        return "Floor Price H-L";
    }
    if (sortType === 'Collection Size') {
        return "Count H-L";
    }
    if (sortType === 'Mint Date') {
        return "Release Date L-F";
    }
    return sortType;
}

export const removeDataHavingZeroCountOrUpdateTheCount = (count, key, setValue) => {
    if (!count) {
        setValue((pre) => {
            return pre.filter((el) => !el?.status === key);
        });
    } else {
        setValue((pre) => {
            return pre.map((el) => {
                if (el?.status === key) {
                    return { ...el, count: count };
                }
                return el;
            });
        });
    }
}


export const truncateTextWithLetters = (text, maxWords) => {
    if (!text) return '';

    if (text?.length > maxWords) {
        return text.slice(0, maxWords) + '... ';
    }
    return text;
};


export const getUpdatedCollectionList = (allNftCollections) => {
    let policyList = [];
    let policy = {};
    allNftCollections.forEach((item) => {
        if (!policy[item.policy]) {
            policy[item.policy] = 1;
        } else {
            policy[item.policy] += 1;
        }
    });
    allNftCollections.forEach((item) => {
        if (policy[item.policy] && policy[item.policy] > 1) {
            const included = policyList.some((x) => x.policy === item.policy);
            if (!included) {
                //altered to include the latest NFT as the collection NFT, so we can use it for sorting most recent
                const latest = allNftCollections.filter((x) => x.policy === item.policy).sort((a, b) => new Date(b.userWalletAssetLastAcquiredDate) - new Date(a.userWalletAssetLastAcquiredDate))?.[0];
                const object = { ...latest, quantity: policy[item.policy] };
                policyList.push(object);
            }
        } else {
            const object = { ...item, quantity: 1 };
            policyList.push(object);
        }
    });

    return policyList
}

export const hasActivePath = (activeLinkBase, pathname) => {
    return activeLinkBase?.some((el) => pathname?.includes(el))
}

export const arraysEqual = (firstArray, secondArray) => {
    if (firstArray?.length !== secondArray?.length) return false;
    return firstArray?.sort()?.every((value, index) => value === secondArray?.sort()[index]);
}

export const isEqualObject = (firstData, secondData) => {
    const keys1 = Object.keys(firstData);
    const keys2 = Object.keys(secondData);

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) return false;

    // Check if all keys in obj1 are in obj2 and have the same value
    return keys1.every(key => {
        if (Array.isArray(firstData[key]) && Array.isArray(secondData[key])) {
            // Check if both arrays are equal
            return firstData[key].length === secondData[key].length &&
                firstData[key].every((value, index) => value === secondData[key][index]);
        }
        return firstData[key] === secondData[key];
    });
}
export const deepEqualCheck = (firstData, secondData) => {
    // Helper function to compare arrays
    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((item, index) => deepEqualCheck(item, arr2[index]));
    };

    // Check if both are objects or arrays
    const isObject = (obj) => obj && typeof obj === 'object';

    // If both are arrays, compare them using arraysEqual
    if (Array.isArray(firstData) && Array.isArray(secondData)) {
        return arraysEqual(firstData, secondData);
    }

    // If both are objects (but not arrays), compare them as objects
    if (isObject(firstData) && isObject(secondData)) {
        const keys1 = Object.keys(firstData);
        const keys2 = Object.keys(secondData);

        // If they don't have the same number of keys, they're not equal
        if (keys1.length !== keys2.length) return false;

        // Check that each key in firstData exists in secondData and is deeply equal
        return keys1.every((key) => deepEqualCheck(firstData[key], secondData[key]));
    }

    // For primitive types, check equality directly
    return firstData === secondData;
};
export const loadArraySegment = ({ list = [], pageNo = 0, itemsPerPage = 0 }) => {
    const offset = (pageNo - 1) * itemsPerPage;
    const limit = (((pageNo - 1) * itemsPerPage) + itemsPerPage);
    return list?.slice(offset, limit);
}
export const rootScrollController = (action = 'enable', noScrollToTop = false) => {

    if (isMobileOS()) {
        const root = document?.documentElement;
        if (!noScrollToTop) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }


        if (action === 'enable') {
            root?.style.setProperty(
                "overflow", `auto`
            );
        }

        if (action === 'disable') {
            root?.style.setProperty(
                "overflow", `hidden`
            );
        }
    }
}

// Function for comparing two objects
export function compareObjectsValues(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!compareObjectsValues(obj1[key], obj2[key])) return false;
    }

    return true;
}

export function checkIOSAppMode() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isStandalone = window.navigator.standalone;

    if (isIOS && isStandalone) {
        return { appMode: true }
    } else if (isIOS) {
        return { appMode: false };
    } else {
        return { appMode: false, otherMode: true }
    }
}
export const filteredCategoryCodeName = (category) => {
    switch (category) {
        case "collectible-creators":
            return "collectibles";
        default:
            return category
    }
}

export const setCategoryCodeName = (category) => {
    switch (category) {
        case "artists":
            return "art";
        case "photographers":
            return "photography";
        case "musicians":
            return "music";
        case "collectible-creators":
            return "collectibles";
        case "art":
            return "artists";
        case "photography":
            return "photographers";
        case "music":
            return "musicians";
        case "collectibles":
            return "collectible-creators";
        case "literature":
            return "authors";
        case "authors":
            return "literature";
        default:
            return category
    }
}

export const bytesConvertHandler = (value, type) => {
    let valueInNumber = Number(value) || 0;
    switch (type) {
        case "mb-to-gb":
            return (valueInNumber / 1024)?.toFixed(1)
        default:
            return value
    }
}

export const generateTimelineLists = (timelineCollectionList, basePath, doSort) => {
    let updatedList = timelineCollectionList?.map(c => {
        let urlBase;
        if (c?.community) {
            urlBase = (basePath ? `${basePath}${c?.community?.slug}` : window.location.pathname.replace('/timeline', '').replace('/overview', '')) + `/${ROUTES_WORDS?.COLLECTION_TAB}`;
        } else {
            urlBase = `discover/${ROUTES_WORDS?.COLLECTION_TAB}/details`
        }
        return { ...c, date: c.mintDate, urlbase: urlBase }
    })
    return doSort ? updatedList?.sort((a, b) => new Date(b.mintDate) - new Date(a.mintDate)) : updatedList
}

export const getComparedDateData = (fromDate = null, toDate) => {

    const currentDate = fromDate ? new Date(fromDate) : new Date();
    const lastUpdated = new Date(toDate);

    const timeDifference = Math.abs(currentDate - lastUpdated); // Difference in milliseconds

    const hours = Math.floor(timeDifference / 3600000); // 1000 * 60 * 60
    const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1000 * 60
    const seconds = Math.floor((timeDifference % 60000) / 1000); // 1000
    const milliseconds = timeDifference % 1000;

    return { hours, minutes, seconds, milliseconds };
}

export const getFileType = (type) => {
    switch (type?.toLowerCase()) {
        case "html":
            return "text";
        default:
            return type
    }
}

export const fileTypeCheck = (file, fileType) => {
    if (file) {
        const extension = file?.type?.split("/").shift();
        const fileExtension = extension.toLowerCase();
        if (
            (fileExtension === getFileType(fileType)) || (!file?.type && getFileType(fileType) === "model")
        ) {
            return file
        }
        return null
    }
}

export const getArticles = (word) => {
    switch (word?.toLowerCase()) {
        case "audio":
        case "html":
        case "image":
            return "an";

        case "modal":
        case "music":
            return "a";
        default:
            return "a"
    }
}

export const lockAndUnlockScroll = (command) => {
    if (command === 'lock') {
        function lockScroll() {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`; // Keep the page in place
        }
        lockScroll()
    }
    if (command === 'unlock') {
        function unlockScroll() {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1); // Restore scroll position
        }
        unlockScroll()
    }

}

export const getAcceptType = (mediaType) => {
    const os = getOperatingSystem()
    if (os === 'iOS' || os === 'Safari') {
        switch (mediaType) {
            case 'image':
                return 'image/*';
            case 'video':
                return 'video/*,.mp4,.avi,.mov,.mkv,.webm,.flv,.wmv';
            case 'audio':
                return 'audio/*,.mp3,.wav,.ogg,.flac,.aac,.m4a';
            case 'pdf':
                return 'application/pdf';
            default:
                return '.obj,.fbx,.stl,.dae,.glb,.gltf,.html';
        }
    } else {
        switch (mediaType) {
            case 'image':
                return 'image/*';
            case 'video':
                return 'video/*';
            case 'music':
            case 'audio':
                return 'audio/*';
            case 'pdf':
                return 'application/pdf';
            default:
                return '*/*';
        }
    }
};

export function findOrientation(resolution, orientation) {
    if (!resolution) return 'landscape';

    const [width, height] = resolution.split('x').map(Number);

    // passing orientation itself if "portrait" 
    // ie: some cases portrait width & height come vise versa
    if (orientation && orientation === "portrait") {
        return 'portrait';
    }
    // Determine the orientation based on width and height
    if (width === height) {
        return 'square';
    } else if (width > height) {
        return 'landscape';
    } else {
        return 'portrait';
    }
}

export const bytesToHex = (bytes) => {
    return Array.from(
        bytes,
        byte => byte.toString(16).padStart(2, "0")
    ).join("");
}

export const UTF8ToHex = (string) => {
    // Preprocess the string: Remove spaces and special characters like #
    const sanitizedString = string.replace(/[^a-zA-Z0-9]/g, '');
    return bytesToHex(new TextEncoder().encode(sanitizedString));
}
export const scrollIntoView = ({ options = { behavior: 'smooth', block: 'start', inline: 'nearest' }, id = 'mainContent' }) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
        targetDiv.scrollIntoView(options);
    }
}
export const scrollToContentTop = () => {
    scrollIntoView({ id: 'content-scroll' });
}

export const scrollToFirstInvalidField = () => {
    const firstInvalidField = document.querySelector('[data-required="true"][data-empty="true"], [data-invalid="true"]');
    if (firstInvalidField) {
        firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

export const updateElementData = (data) => {
    let keyName = Object.keys(data)[0];
    const formField = document.querySelector(`[data-name="${keyName}"]`);
    if (formField) {
        formField.setAttribute('data-empty', !data[keyName] ? 'true' : 'false');
        const type = formField.getAttribute("data-type");
        if (type) {
            formField.setAttribute('data-invalid', (data[keyName] ? validationCheck({ type, value: data[keyName] }) ? 'true' : 'false' : 'false'));
        }
    }
}

export const getCreatorsAsArrayFromCollections = (collections) => {
    if (!collections) return [];

    const creatorObject = collections.reduce((acc, collection) => {
        const community = collection?.community;
        const uniqueCode = community?.communitySlug;

        if (uniqueCode) {
            if (!acc[uniqueCode]) {
                acc[uniqueCode] = {
                    name: community.name,
                    slug: uniqueCode,
                    totalCount: 0, // Initialize the count
                    showTotal: true,
                    ...community
                };
            }
            acc[uniqueCode].totalCount += 1;
        }
        return acc;
    }, {});

    return Object?.values(creatorObject)?.sort((a, b) => a?.name.localeCompare(b?.name)); // Sort A-Z
}
export const getBrowser = () => {
    const { userAgent, userAgentData } = navigator;
    let browser = "Unknown";

    if (userAgentData?.brands?.length) {
        const preferredBrands = ["Google Chrome", "Microsoft Edge", "Firefox", "Safari", "Opera", "Brave", "Chromium"];
        const detectedBrand = userAgentData.brands.find(b => preferredBrands.includes(b.brand));
        if (detectedBrand) {
            browser = detectedBrand.brand;
        }
    }
    if (browser === "Unknown") {
        if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            browser = "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            browser = "Safari";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Edg")) {
            browser = "Edge";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            browser = "Opera";
        } else if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
            browser = "Internet Explorer";
        }
    }
    return browser;
};


export const groupMyCollectionsByCommunity = (collections, communityId, lastObjectName = 'Unknown') => {
    if (!collections?.length) return null;

    const groupedCollections = {};
    const matchingCollections = collections.filter(
        (collection) => collection?.community?.communitySlug === communityId
    );

    if (!matchingCollections?.length) return null;
    const updatedCollectionList = getUpdatedCollectionList(matchingCollections);

    matchingCollections.forEach((collection) => {
        const uniqueCollectionName = collection?.collectionName;
        if (!groupedCollections[uniqueCollectionName]) {
            groupedCollections[uniqueCollectionName] = { header: uniqueCollectionName, data: [], collectionId: collection?.collectionId };
        }

        groupedCollections[uniqueCollectionName]?.data.push(collection);
    });

    let sortData = [];
    let lastObject = null;
    Object.entries(groupedCollections).forEach(([key, value]) => {
        if (value?.data?.length) {
            value.data = value.data.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
        }
        if (value?.header === lastObjectName) {
            lastObject = value;
        } else {
            const details = updatedCollectionList?.find((item) => item?.collectionId === value?.collectionId);
            sortData.push({ ...details, ...value })
        }
    })
    sortData = sortData?.sort((a, b) => a["header"] ? b["header"] ? a["header"]?.toLowerCase() > b["header"]?.toLowerCase() ? 1 : -1 : -1 : 1);
    if (!!lastObject) {
        sortData?.push(lastObject);
    }
    return sortData;
};

export const getIsProofOfCraftApplicable = (collectionDetails) => {
    if (!collectionDetails?.community?.id) return false;

    const { category, proofOfCraft } = collectionDetails;
    const isArtCategory = category?.name === 'Art';
    const hasProofOfCraft = !!proofOfCraft?.length;

    return isArtCategory || hasProofOfCraft;
};

export const removeFromWord = (inputString, words) => {
    if (Array.isArray(words)) {
        // Find the first occurrence of any word in the array
        let minIndex = inputString.length;
        for (const word of words) {
            const index = inputString.indexOf(word);
            if (index !== -1 && index < minIndex) {
                minIndex = index;
            }
        }

        return minIndex < inputString.length ? inputString.slice(0, minIndex) : inputString;
    } else {
        // Handle a single word
        const index = inputString.indexOf(words);
        return index !== -1 ? inputString.slice(0, index) : inputString;
    }
}

export const formatDate = (dateString, options = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
};

export const shortenMonthName = (dateString) => {
    const monthAbbreviations = {
        January: 'Jan', February: 'Feb', March: 'Mar',
        April: 'Apr', May: 'May', June: 'Jun',
        July: 'Jul', August: 'Aug', September: 'Sep',
        October: 'Oct', November: 'Nov', December: 'Dec'
    };

    for (const [month, abbreviation] of Object.entries(monthAbbreviations)) {
        if (dateString.includes(month)) {
            return dateString.replace(month, abbreviation);
        }
    }
    return dateString; // Return original string if no month name is found
};
export const extractTwitterUsername = (url) => {
    // Match a username from a Twitter/X URL pattern
    const match = url?.match(/(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/(?:#!\/)?@?([\w]+)/i);
    return match ? match[1] : url; // Return the username or null if no match
}

export function checkArraysInsideObjectIsEmpty(obj) {
    // Loop through all the keys in the object
    for (const key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
            // Return true if any array is not empty
            return true;
        }
    }
    // Return false if all arrays are empty
    return false;
}

export function formatUtilities(utilities, maxItems = 2) {
    if (utilities?.length > maxItems) {
        return utilities.slice(0, maxItems).map((utility) => utility?.name).join(', ') + ' ...';
    }
    return utilities?.map((utility) => utility?.name).join(', ');
}

export const setUtilityIcon = (utility) => {
    switch (utility) {
        case "Tickets":
        case "Ticket":
            return TicketIcon;
        case "Membership":
        case "Memberships":
            return MembershipIcon;
        case "Gaming":
            return GamingIcon;
        case "Asset-Backed":
            return AssetBackedIcon;
        default:
            return ""
    }
}

export const setUtilityDescription = (utility) => {
    switch (utility) {
        case "Tickets":
        case "Ticket":
            return "NFTs that serve as digital tickets, granting access to physical or virtual events, experiences, and venues";
        case "Membership":
        case "Memberships":
            return "NFTs that function as digital membership cards, providing access to exclusive content, services, and holder benefits";
        case "Gaming":
            return "NFTs that serve as functional in-game assets, including playable characters, equipment, cosmetic items, and virtual land";
        case "Asset-Backed":
            return "NFTs that represent ownership rights in physical assets from redeemable products to fractional property ownership";
        default:
            return ""
    }
}

export const selectedCollectionUtilityFromSelectedListHandler = (id, utilities) => {
    let collectionUtilities = utilities;
    collectionUtilities = utilities?.find(el => el?.collectionUtilityId === Number(id));

    return collectionUtilities
};

export function handleSetInitialMainFilterValue(isId = null) {
    if (isId) {
        return { id: isId }
    }
    return { id: 'allCollections' }
}

export const chunkArray = (array, chunkSize) => {
    const result = [];
    let temp = [];

    for (const item of array) {
        temp.push(item);
        if (temp.length === chunkSize) {
            result.push(temp);
            temp = [];
        }
    }

    // Push any remaining elements
    if (temp.length > 0) {
        result.push(temp);
    }

    return result;
}

export const assetListShortInfo = (currentSelectedNfts) => {
    let assetArray = currentSelectedNfts?.map((el, i) => {
        return {
            asset: el?.asset ?? null,
            frameId: el?.frameId ?? null,
            sequence: el?.sequence || (i + 1),
            isLandscape: el?.isLandscape ?? el?.frame?.isLandscape ?? false,
            asset_name: el?.asset_name,
            contractAddress: el?.contractAddress,
            chain: el?.blockchainType?.code,
            policy_id: el?.policy ?? el?.policy_id,
        }

    });
    return assetArray
}
export  const sliceNameFromLink = (link) => {
    return link
      ?.replace("https://www.", "")
      ?.replace("https://", "")
      ?.split("/")[0];
  };
export const getMarketplaceNameOrLogoFromDomain = (domain,type="name") => {
    switch (domain) {
        case "magiceden.io":
            if(type==="logo"){
                return magicEdenImg
            }
            return WORDS?.MARKETPLACES?.MAGIC_EDEN;
        case "jpg.store":
            if(type==="logo"){
                return jpgStoreImg
            }
            return WORDS?.MARKETPLACES?.JPG_STORE;
        case "rarible.com":
            if(type==="logo"){
                return raribleImg
            }
            return WORDS?.MARKETPLACES?.RARIBLE;
        case "artblocks.io":
            if(type==="logo"){
                return artBlockImg
            }
            return WORDS?.MARKETPLACES?.ART_BLOCK;
        case "async.art":
            if(type==="logo"){
                return asyncArtImg
            }
            return WORDS?.MARKETPLACES?.ASYNC_ART;
        case "wax.atomichub.io":
            if(type==="logo"){
                return atomicHubImg
            }
            return WORDS?.MARKETPLACES?.ATOMICHUB;
        case "axieinfinity.com":
            if(type==="logo"){
                return axieInfinityImg
            }
            return WORDS?.MARKETPLACES?.AXIE_INFINITY;
        case "blur.io":
            if(type==="logo"){
                return blurImg
            }
            return WORDS?.MARKETPLACES?.BLUR;
        case "drip.haus":
            if(type==="logo"){
                return dripImg
            }
            return WORDS?.MARKETPLACES?.DRIP_HAUS;
        case "dropspot.io":
            if(type==="logo"){
                return dropspotImg
            }
            return WORDS?.MARKETPLACES?.DROPSPOT;
        case "element.market":
            if(type==="logo"){
                return elementImg
            }
            return WORDS?.MARKETPLACES?.ELEMENT;
        case "enjin.io":
            if(type==="logo"){
                return enjinImg
            }
            return WORDS?.MARKETPLACES?.ENJIN;
        case "exchange.art":
            if(type==="logo"){
                return exchangeArtImg
            }
            return WORDS?.MARKETPLACES?.EXCHANGE_ART;
        case "fp.io":
            if(type==="logo"){
                return folorProtocolImg
            }
            return WORDS?.MARKETPLACES?.FLOOR_PROTOCOL;
        case "foundation.app":
            if(type==="logo"){
                return foundationImg
            }
            return WORDS?.MARKETPLACES?.FOUNDATION;
        case "avax.hyperspace.xyz":
            if(type==="logo"){
                return hyperspaceImg
            }
            return WORDS?.MARKETPLACES?.HYPERSPACE;
        case "infinity8.io":
            if(type==="logo"){
                return infinity8Img
            }
            return WORDS?.MARKETPLACES?.INFINITY_8;
        case "knownorigin.io":
            if(type==="logo"){
                return knownOriginImg
            }
            return WORDS?.MARKETPLACES?.KNOWN_ORIGIN;
        case "kreate.art":
            if(type==="logo"){
                return kreateImg
            }
            return WORDS?.MARKETPLACES?.KERATE;
        case "lfj.gg":
            if(type==="logo"){
                return lfjImg
            }
            return WORDS?.MARKETPLACES?.LFJ;
        case "looksrare.org":
            if(type==="logo"){
                return looksRareImg
            }
            return WORDS?.MARKETPLACES?.LOOKS_RARE;
        case "makersplace.com":
            if(type==="logo"){
                return makersPlaceImg
            }
            return WORDS?.MARKETPLACES?.MAKERSPLACE;
        case "mallow.art":
            if(type==="logo"){
                return mallowImg
            }
            return WORDS?.MARKETPLACES?.MALLOW;
        case "manifold.xyz":
            case "manifold.gallery":
            if(type==="logo"){
                return manifoldImg
            }
            return WORDS?.MARKETPLACES?.MANIFOLD;
        case "mintable.com":
            if(type==="logo"){
                return mintableImg
            }
            return WORDS?.MARKETPLACES?.MINTABLE;
        case "niftygateway.com":
            if(type==="logo"){
                return niftyGatewayImg
            }
            return WORDS?.MARKETPLACES?.NIFTY_GATEWAY_STUDIO;
        case "objkt.com":
            if(type==="logo"){
                return objktImg
            }
            return WORDS?.MARKETPLACES?.OBJKT;
        case "okx.com":
            if(type==="logo"){
                return okxImg
            }
            return WORDS?.MARKETPLACES?.OKX;
        case "opensea.io":
            if(type==="logo"){
                return openseaImg
            }
            return WORDS?.MARKETPLACES?.OPENSEA;
        case "remx.xyz":
            if(type==="logo"){
                return remxImg
            }
            return WORDS?.MARKETPLACES?.REMX;
        case "solanart.io":
            if(type==="logo"){
                return solanartImg
            }
            return WORDS?.MARKETPLACES?.SOLANART;
        case "superrare.com":
            if(type==="logo"){
                return suprerRareImg
            }
            return WORDS?.MARKETPLACES?.SUPER_RARE;
        case "tensor.trade":
            if(type==="logo"){
                return tensorImg
            }
            return WORDS?.MARKETPLACES?.TENSOR;
        case "tokentrove.com":
            if(type==="logo"){
                return troveImg
            }
            return WORDS?.MARKETPLACES?.TOKEN_TROVE;
        case "trader.xyz":
            if(type==="logo"){
                return traderImg
            }
            return WORDS?.MARKETPLACES?.TRADER;
        case "x2y2.io":
            if(type==="logo"){
                return x2y2Img
            }
            return WORDS?.MARKETPLACES?.X2Y2;
        case "zora.co":
            if(type==="logo"){
                return zoraImg
            }
            return WORDS?.MARKETPLACES?.ZORA;
        default:
            if(type==="logo"){
                return MarketplaceIcon
            }
            return ""
    }
}

export const getCollectionsSearchResult = (data, searchText) => {
    if (!searchText) return data;

    const searchTextLower = searchText.toLowerCase();

    return data?.filter(item => 
        ['name', 'community.name', 'asset', 'collectionName', 'contractAddress'].some(prop => 
            item?.name?.toLowerCase()?.includes(searchTextLower) || 
            item?.community?.name?.toLowerCase()?.includes(searchTextLower) || 
            item?.asset?.toLowerCase()?.includes(searchTextLower) || 
            item?.collectionName?.toLowerCase()?.includes(searchTextLower) || 
            item?.contractAddress?.toLowerCase()?.includes(searchTextLower)
        )
    );
};

export const getSingleSubjectArray = (subjectsArray = []) => {
    let data = subjectsArray;
    let result = data?.reduce((acc, category) => {
        const subcategoriesWithParentId = category?.subcategories.map(subcategory => ({
            ...subcategory,
            parentId: category.id,
            value: subcategory.id
        }));
        return acc.concat(subcategoriesWithParentId);
    }, []);
    if (result?.length) {
        result.sort((a, b) => a?.name.localeCompare(b?.name));
    }
    return result;
};